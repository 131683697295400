import React from "react";
import oneGigaAudio from "../assets/audio/onegiga-audio.mp3";

const AudioButton = () => {
  
  return (
    <div className="flex items-center px-2 h-full">
      <audio autoPlay controls loop className="h-2/3">
        <source src={oneGigaAudio} type="audio/mp3" />
      </audio>
    </div>
  );
};

export default AudioButton;



