import React from "react";
import TopBar from "./components/TopBar";
import AdToonSection from "./components/AdToonSection";
import GoToTopButton from "./components/GoToTopButton";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <TopBar />
      
      <AdToonSection />
      <GoToTopButton />
      <Footer />
    </>
  )
}

export default App;
