import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

const GoToTopButton = () => {
  const [scrollAmount, setScrollAmount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.scrollY;
      setScrollAmount(currentScroll);

      // scroll 위치에 따라 버튼 표시 유무 설정
      setIsVisible(currentScroll > 600);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // 브라우저 맨 위로 이동
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {/* scroll이 600 이상인 경우만 버튼 표시 */}
      {isVisible && (
        <button
          className="flex justify-center items-center fixed bottom-10 xl:bottom-20 right-1 xl:right-24 w-10 xl:w-14 h-10 xl:h-14 border-2 border-solid border-mainColor rounded-full bg-mainColor text-xl"
          id="goToTop"
          onClick={scrollToTop}
        >
          <FontAwesomeIcon icon={faArrowUp} />
        </button>
      )}
    </>
  );
};

export default GoToTopButton;
