import React from "react";
import FooterIamge from "../assets/images/footer_01.png";

const Footer = () => {
  return (
    <>
      <footer className="w-full h-auto lg:h-64 bg-footerColor pt-10 lg:pt-0 text-textColor">
        <div className="block lg:flex w-full xl:w-5/6 h-full mx-auto lg:px-5">
          <div
            className="flex justify-between items-center order-2 w-full px-5 md:px-10 bg-footerColor2"
            id="footerCsCenter"
          >
            <figure className="w-24 md:w-32 h-24 md:h-32">
              <img src={FooterIamge} alt="FooterIamge" />
            </figure>
            <div className="w-4/5 md:w-3/5 text-right">
              <p className="pb-2">cs center</p>
              <a className="text-4xl font-bold" href="tel:16447174">
                1644-7174
              </a>
              <p className="pt-7 text-sm text-textColor2">
                평일 09:30 - 18:00 &nbsp;점심시간 12:00 - 13:00 <br />
                주말 및 공휴일은 카톡상담 가능합니다
              </p>
            </div>
          </div>
          <div className="order-1 w-auto my-auto px-14 lg:px-0 py-10 lg:py-0 text-sm text-textColor2">
            <ul className="flex">
              <li className="mr-8">
                <a
                  href="/contents/common/agree.html"
                  data-gnb="1"
                  data-gnb-class="agree"
                >
                  이용약관
                </a>
              </li>
              <li>
                <a
                  href="/contents/common/privacy.html"
                  data-gnb="1"
                  data-gnb-class="privacy"
                >
                  개인정보처리방침
                </a>
              </li>
            </ul>
            <address className="flex flex-wrap mt-4 not-italic">
              <span className="py-2 pr-2">회사명 : 일기가</span>
              <span className="py-2 pr-2">대표 : 이명우</span>
              <span className="py-2 pr-2">사업자번호 : 244-16-01672</span>
              <span className="py-2 pr-2">통신판매신고 : 2024-대전서구-0193</span>
              <span className="py-2 pr-2">주소 : 대전 서구 둔산로63,4층 403-207호</span>
              <span className="py-2 pr-2">
                대표번호 : <a href="tel:16447174">1644-7174</a>
              </span>
            </address>
            <p className="mt-4">
              Copyright 1기가 일기가. All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
