import React, { useEffect, useRef, useState } from "react";
import oneGigaAudio from "../assets/audio/onegiga-audio.mp3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";

const MobileAudioButton = () => {
  const [isPause, setIsPause] = useState(true); // 최초 음소거 상태
  const audioRef = useRef(null);
  // 사용자 상호작용 추적
  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    if (hasInteracted) {
      if (isPause) {
        audioRef.current.pause(); // 음소거 상태는 정지
      } else {
        audioRef.current.play(); // 음소거 해제된 상태는 재생
      }
    }
  }, [hasInteracted, isPause]);

  const togglePause = () => {
    setIsPause(!isPause); // 음소거 상태 토글
  };
  return (
    <div className="w-8 h-full mr-1">
      <button
        className="flex justify-center items-center w-full h-full text-mainColor"
        onClick={() => {
          setHasInteracted(true); // 사용자 상호 작용 감지
          togglePause(); // mute 상태 토글
          console.log(hasInteracted);
        }}
      >
        {isPause ? (
          <FontAwesomeIcon className="w-1/2 h-1/2" icon={faPlay} />
        ) : (
          <FontAwesomeIcon className="w-1/2 h-1/2" icon={faPause} />
        )}
      </button>
      <audio ref={audioRef} autoPlay loop muted={isPause}>
        <source src={oneGigaAudio} type="audio/mp3" />
      </audio>
    </div>
  );
};

export default MobileAudioButton;
