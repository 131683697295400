import React, { useState, useEffect } from "react";
import Logo from "../assets/images/logo.jpg";
import MobileLogo from "../assets/images/Thumbnail.jpg"
import AudioButton from "./AudioButton";
import MobileAudioButton from "./MobileAudioButton";

const TopBar = () => {
  // 브라우저 크기 상태
  const [isWideScreen, setIsWideScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 500);
    };

    handleResize(); // 초기 실행
    window.addEventListener("resize", handleResize); // 창 크기 변경 감지
    return () => {
      window.removeEventListener("resize", handleResize); // 초기화
    };
  }, []);

  return (
    <>
      <nav className="fixed z-10 top-0 left-0 flex justify-end items-center w-full h-14 bg-textColor border-b-4 border-solid border-mainColor">
        <div className="hidden md:flex items-center w-auto h-full pl-2 lg:pl-4">
          <img src={Logo} alt="logo" className="w-full h-3/5" />
        </div>
        <div className="flex md:hidden items-center w-11 h-full pl-2 lg:pl-4">
          <img src={MobileLogo} alt="logo" className="w-full h-3/5" />
        </div>
        <div className="flex-grow"></div>
        <div className="flex items-center w-auto h-auto px-2 border-r-2 border-solid border-mainColor">
          <a
            href="https://www.xn--1-3x5e01d.com/"
            target="_blank"
            className="w-full font-bold text-mainColor text-sm lg:text-base"
          >
            사이트 바로가기
          </a>
        </div>
        <div className="flex items-center w-auto h-auto px-2 border-r-2 border-solid border-mainColor">
          <a href="tel:16447174" className="w-full font-bold text-mainColor text-sm lg:text-base">
            상담신청
          </a>
        </div>
        <div className="flex items-center w-auto h-auto mr-2 px-2">
          <a
            href="https://www.xn--1-3x5e01d.com/online.html?online_id=koweb_online_application"
            target="_blank"
            className="w-full font-bold text-mainColor text-sm lg:text-base"
          >
            가입신청
          </a>
        </div>
        {isWideScreen ? <AudioButton /> : <MobileAudioButton />}
      </nav>
    </>
  );
};

export default TopBar;
