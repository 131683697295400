import React from "react";
import Thumbnail from "../assets/images/Thumbnail.jpg";
import AdToonCut01 from "../assets/images/adtoon-cut-01.jpg";
import AdToonCut02 from "../assets/images/adtoon-cut-02.jpg";
import AdToonCut03 from "../assets/images/adtoon-cut-03.jpg";
import AdToonCut04 from "../assets/images/adtoon-cut-04.jpg";
import AdToonCut05 from "../assets/images/adtoon-cut-05.jpg";
import AdToonCut06 from "../assets/images/adtoon-cut-06.jpg";
import AdToonCut07 from "../assets/images/adtoon-cut-07.jpg";
import AdToonCut08 from "../assets/images/adtoon-cut-08.jpg";
import AdToonCut09 from "../assets/images/adtoon-cut-09.jpg";
import AdToonCut10 from "../assets/images/adtoon-cut-10.jpg";
import AdToonCut11 from "../assets/images/adtoon-cut-11.jpg";
import AdToonCut12 from "../assets/images/adtoon-cut-12.jpg";
import AdToonCut13 from "../assets/images/adtoon-cut-13.jpg";
import AdToonCut14 from "../assets/images/adtoon-cut-14.jpg";
import ImageCut from "./ImageCut";

const AdToonSection = () => {
  const images = [
    { src: Thumbnail, alt: Thumbnail },
    { src: AdToonCut01, alt: AdToonCut01 },
    { src: AdToonCut02, alt: AdToonCut02 },
    { src: AdToonCut03, alt: AdToonCut03 },
    { src: AdToonCut04, alt: AdToonCut04 },
    { src: AdToonCut05, alt: AdToonCut05 },
    { src: AdToonCut06, alt: AdToonCut06 },
    { src: AdToonCut07, alt: AdToonCut07 },
    { src: AdToonCut08, alt: AdToonCut08 },
    { src: AdToonCut09, alt: AdToonCut09 },
    { src: AdToonCut10, alt: AdToonCut10 },
    { src: AdToonCut11, alt: AdToonCut11 },
    { src: AdToonCut12, alt: AdToonCut12 },
    { src: AdToonCut13, alt: AdToonCut13 },
    { src: AdToonCut14, alt: AdToonCut14 },
  ];
  return (
    <div  className="mt-20">
      <section className="w-11/12 xl:w-1/2 h-auto mx-auto pb-40">
        {images.map((image, index) => (
          <ImageCut key={index} imageSrc={image.src} imageAlt={image.alt} />
        ))}
      </section>
    </div>
  );
};

export default AdToonSection;
